import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaYelp, FaFacebook, FaGoogle } from "react-icons/fa";
import ListGroup from "react-bootstrap/ListGroup";

export default () => {
  const iconStyle = {
    fontSize: '2rem',
    textDecoration: 'none',
    color: '#9e040b'
  };
  return(
    <Layout>
      <Container>
        <Row className="justify-content-md-center justify-content-sm-center">
          <Col lg="6" sm="6" xs="12">
            <h1>Contact Us</h1>
            <p>We love to hear from our customers. You might like to contact us to:</p>
            <ListGroup variant="flush">
              <ListGroup.Item>Make an appointment</ListGroup.Item>
              <ListGroup.Item>Inquire about our services</ListGroup.Item>
              <ListGroup.Item>Inquire about information on the web site</ListGroup.Item>
              <ListGroup.Item>Provide feedback on services you received</ListGroup.Item>
            </ListGroup>
          </Col>
          <Col xs="12" lg="2" sm="6" className="contact-info">
            <h2>Phone</h2>
            <p>Call me at: <a href={"tel:5039307649"}>(503) 930-7649</a></p>
            <h2>Address</h2>
            <address>
              1292 Shady Ln NE<br/>
              Keizer, Oregon 97303<br/>
            </address>
            <h2>Social</h2>
            <a title="Yelp" style={iconStyle} href={"http://www.yelp.com/biz/rugged-cross-auto-detail-keizer"} target={"_blank"}><FaYelp/></a>
            <a title="Facebook" style={iconStyle} href={"http://www.facebook.com/pages/Rugged-Cross-Auto-Detail/308727003139"} target={"_blank"}><FaFacebook/></a>
            <a title="Google Reviews" style={iconStyle} href={"https://g.page/ruggedcrossdetail/review?gm"} target={"_blank"}><FaGoogle/></a>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col>

          </Col>
          <Col>

          </Col>
        </Row>
        <Row className={"extra-space"}>
          <Col>
            <h2>Email Us:</h2>
            <Form action="https://formspree.io/xknrnwvr" method="POST">
              <Form.Row>
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control type="text" placeholder="First and Last" id="full-name" name="name"/>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" name="_replyto" id="email-address" placeholder="Email" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridSubject">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control type="text" placeholder="Subject" id="email-subject" name="_subject"/>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridSubject">
                  <Form.Label>Telephone</Form.Label>
                  <Form.Control type="telephone" name="telephone" id="telephone" placeholder="(555) 555-5555"/>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridMessage">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" type="text" name="message" id="message" placeholder="Aenean lacinia bibendum nulla sed consectetur. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec ullamcorper nulla non metus auctor fringilla nullam quis risus."/>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group>
                    <Button variant="dark" type="submit">Send</Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </Layout>

  )
}
